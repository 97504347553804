import classNames from 'classnames';
import { TariffStatus } from '@modules/AdminPage/services/types';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { useEffect } from 'react';
import { getTariffById, updateTariff } from '@modules/AdminPage/services';
import { useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { type DatashopTariff } from '@libs/types';
import { InputNumber } from 'primereact/inputnumber';
import { Divider } from 'primereact/divider';
import { useModal } from '@libs/utils';
import { usePermissions } from '@libs/hooks';
import { HasAccess } from '@libs/components/access';

import styles from './styles.module.scss';
import { TariffPlansForm } from './TariffPlanForm';
import { PermissionModal } from './PermissionsModal';

const statusOptions = Object.values(TariffStatus).map((el) => {
    return {
        name: el,
        code: el,
    };
});

export const TariffPage = () => {
    const { tariffId } = useParams();

    const {
        control,
        reset,
        watch,
        handleSubmit,
        formState: { isDirty, isValid },
    } = useForm<DatashopTariff>({
        mode: 'onBlur',
    });

    const { isAdmin, rootAdminPermissions } = usePermissions();
    const { isOpen, closeModal, openModal } = useModal();

    const { fields } = useFieldArray({
        control,
        name: 'billingTables',
    });

    const onSubmit = async (data: DatashopTariff) => {
        try {
            if (tariffId) {
                await updateTariff(tariffId, {
                    ...data,
                    billingTables: data.billingTables.map((el, index) => {
                        return {
                            ...el,
                            tariffPlans: el.tariffPlans.map((plan, planIndex) => {
                                return {
                                    ...plan,
                                    tariffPlanId: fields[index].tariffPlans[planIndex].tariffPlanId,
                                };
                            }),
                        };
                    }),
                }).then(() => window.location.reload());
            }
        } catch (error) {
            console.log('error: ', error);
        }
    };

    useEffect(() => {
        const fetchTariff = async () => {
            try {
                if (tariffId) {
                    await getTariffById(tariffId).then((data) => {
                        reset(data.data);
                    });
                }
            } catch (error) {
                console.log('error: ', error);
            }
        };

        fetchTariff();
    }, []);

    return (
        <div className={classNames(styles.form, 'flex', 'flex-column')}>
            <div className={classNames('flex', 'flex-column', styles.formFields)}>
                <div className={classNames('flex', 'align-items-center')}>
                    <span className={classNames(styles.title)}>Редактирование тарифа</span>
                    <Button
                        severity="secondary"
                        outlined
                        onClick={openModal}
                        className={classNames(styles.changeButton)}
                    >
                        Управление доступами
                    </Button>
                </div>
                <Controller
                    control={control}
                    name="id"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                        <div className="flex flex-column gap-2">
                            <label htmlFor="id">ID</label>
                            <InputNumber
                                {...field}
                                className={classNames(styles.dropdownInput, fieldState.error && styles.redBorder)}
                                value={field.value}
                                disabled
                            />
                        </div>
                    )}
                />
                <Controller
                    control={control}
                    name="guid"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                        <div className="flex flex-column gap-2">
                            <label htmlFor="guid">ID ТПК</label>
                            <InputText
                                {...field}
                                className={classNames(styles.dropdownInput, fieldState.error && styles.redBorder)}
                                value={field.value}
                                disabled
                            />
                        </div>
                    )}
                />
                <Controller
                    control={control}
                    name="name"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                        <div className="flex flex-column gap-2">
                            <label htmlFor="name">Название</label>
                            <InputText
                                {...field}
                                className={classNames(styles.dropdownInput, fieldState.error && styles.redBorder)}
                                value={field.value}
                                disabled
                            />
                        </div>
                    )}
                />
                <Controller
                    control={control}
                    name="mnemoCode"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                        <div className="flex flex-column gap-2">
                            <label htmlFor="mnemoCode">Мнемокод</label>
                            <InputText
                                {...field}
                                className={classNames(styles.dropdownInput, fieldState.error && styles.redBorder)}
                                value={field.value}
                                disabled
                            />
                        </div>
                    )}
                />
                <Controller
                    control={control}
                    name="clientCategory"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                        <div className="flex flex-column gap-2">
                            <label htmlFor="clientCategory">Категория клиента</label>
                            <InputText
                                {...field}
                                className={classNames(styles.dropdownInput, fieldState.error && styles.redBorder)}
                                value={field.value}
                                disabled
                            />
                        </div>
                    )}
                />
                <Controller
                    control={control}
                    name="clientResident"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                        <div className="flex flex-column gap-2">
                            <label htmlFor="clientResident">Признак резидентства</label>
                            <InputText
                                {...field}
                                className={classNames(styles.dropdownInput, fieldState.error && styles.redBorder)}
                                value={field.value ? 'Да' : 'Нет'}
                                disabled
                            />
                        </div>
                    )}
                />
                <Controller
                    control={control}
                    name="status"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                        <div className="flex flex-column gap-2">
                            <label htmlFor="status">Статус</label>
                            <Dropdown
                                {...field}
                                options={statusOptions}
                                optionLabel="name"
                                placeholder="Статус"
                                optionValue="code"
                                className={classNames(styles.dropdownInput)}
                                disabled={!isAdmin}
                            />
                            {fieldState.error && (
                                <span className={classNames(styles.errorHint)}>Пожалуйста, заполните поле</span>
                            )}
                        </div>
                    )}
                />
                <Divider className={classNames(styles.divider)} />
                <span className={classNames(styles.title)}>Тарифные планы</span>
                {fields.map((table, tableIndex) => (
                    <TariffPlansForm index={tableIndex} control={control} key={table.guid} />
                ))}
                <HasAccess permissions={rootAdminPermissions}>
                    <div className={classNames('flex', 'align-item-center', styles.buttonsContainer)}>
                        <Button severity="secondary" outlined onClick={() => window.location.reload()}>
                            Отмена
                        </Button>
                        <Button
                            severity="secondary"
                            outlined
                            onClick={handleSubmit(onSubmit)}
                            disabled={!isDirty || !isValid}
                        >
                            Изменить
                        </Button>
                    </div>
                </HasAccess>
            </div>
            {watch('productGuid') && watch('guid') && (
                <PermissionModal
                    isOpen={isOpen}
                    closeModal={closeModal}
                    productGuid={watch('productGuid')}
                    tariffGuid={watch('guid')}
                />
            )}
        </div>
    );
};
