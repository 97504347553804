import classNames from 'classnames';
import { Button } from 'primereact/button';
import { HasAccess } from '@libs/components/access';
import { usePermissions } from '@libs/hooks';

import { editNotificationPageFields } from './constants';
import styles from './styles.module.scss';
import { useNotificationsEditFacade } from './useNotificationEditFacade';

export const NotificationEditPage = () => {
    const { renderItem, handleSubmit, isValid, onSubmit, isDirty } = useNotificationsEditFacade();
    const { rootAdminAndMarket } = usePermissions();

    return (
        <div className={classNames(styles.form, 'flex', 'flex-column')}>
            <div className={classNames('flex', 'flex-column', styles.formFields)}>
                <span className={classNames(styles.title)}>Редактирование уведомления</span>
                {editNotificationPageFields.map((field) => renderItem(field))}
                <HasAccess permissions={rootAdminAndMarket}>
                    <div className={classNames('flex', 'align-item-center', styles.buttonsContainer)}>
                        <Button severity="secondary" outlined onClick={() => window.location.reload()}>
                            Отмена
                        </Button>
                        <Button
                            severity="secondary"
                            outlined
                            disabled={!isValid || !isDirty}
                            onClick={handleSubmit(onSubmit)}
                        >
                            Изменить
                        </Button>
                    </div>
                </HasAccess>
            </div>
        </div>
    );
};
