import { useEffect, useState } from 'react';
import { Controller, type UseFormHandleSubmit, useFieldArray, useForm } from 'react-hook-form';
import { type Product } from '@libs/types';
import { hideLoader, showLoader } from '@store/store/slices/loading.slice';
import { store } from '@store/store';
import { getProducts } from '@modules/AdminPage/services';
import { type FileUploadSelectEvent } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import { Checkbox } from 'primereact/checkbox';
import CustomFileUpload from '@libs/components/data/CustomFileUpload';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import { useNavigate } from 'react-router-dom';
import { convertDateToMoscowDateString } from '@libs/utils';

import styles from './../NotificationEditPage/styles.module.scss';
import { createNotification, getTargetGroups } from '../services';
import {
    type CreateNotificationFormInfo,
    type FormControlType,
    FormFieldsTypes,
    NotificationCategory,
    NotificationCategoryMatch,
    type TargetGroupType,
} from '../services/types';

type UseNotificationsCreateFacadeResult = {
    renderItem: (item: FormControlType) => JSX.Element | null;
    handleSubmit: UseFormHandleSubmit<CreateNotificationFormInfo, undefined>;
    onSubmit: (data: CreateNotificationFormInfo) => void;
    isValid: boolean;
    isSubmitting: boolean;
};

const categoryOptions = Object.values(NotificationCategory).map((el) => {
    return {
        label: NotificationCategoryMatch[el],
        value: el,
    };
});

export const useNotificationsСreateFacade = (): UseNotificationsCreateFacadeResult => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const {
        control,
        watch,
        reset,
        handleSubmit,
        setValue,
        trigger,
        formState: { isValid },
    } = useForm<CreateNotificationFormInfo>({
        mode: 'onChange',
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'targetGroups',
    });

    const onSubmit = async (data: CreateNotificationFormInfo) => {
        setIsSubmitting(true);
        const formData = new FormData();

        Object.entries(data).forEach(([key, value]) => {
            if (value !== undefined && value !== null) {
                if (Array.isArray(value)) {
                    let groups: any[] = [];

                    value.forEach((item) => {
                        groups = [
                            ...groups,
                            ...[
                                item.productGuid
                                    ? {
                                          id: item._id,
                                          productGuid: item.productGuid,
                                      }
                                    : {
                                          id: item._id,
                                      },
                            ],
                        ];
                    });

                    if (groups.length) {
                        formData.append('targetGroups', JSON.stringify(groups));
                    }
                } else {
                    formData.append(key, value as string);
                }
            }
        });

        try {
            await createNotification(formData).then((res) => {
                if (res.data.data.id) {
                    navigate(`/notifications/${res.data.data.id}`);
                }
            });
        } catch (error) {
            console.log('error: ', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const [products, setProducts] = useState<Product[]>();
    const [targetGroups, setTargetGroups] = useState<TargetGroupType[]>();
    const [image, setImage] = useState<string>();

    const fetchTargetGroups = async () => {
        try {
            store.dispatch(showLoader());
            const data = await getTargetGroups();
            setTargetGroups(data.data.data);
            store.dispatch(hideLoader());
        } catch (error) {
            console.log('error: ', error);
        }
    };

    const fetchProducts = async () => {
        try {
            const data = await getProducts({});
            setProducts(data.data.data.rows);
        } catch (error) {
            console.log('error: ', error);
        }
    };

    useEffect(() => {
        fetchTargetGroups();
    }, []);

    const handleUploadFile = (e: FileUploadSelectEvent) => {
        setValue('image', e.files[0]);
        const fileUrl = URL.createObjectURL(e.files[0]);
        setImage(fileUrl);
    };

    const handleClearFile = () => {
        setValue('image', undefined);
        setImage('');
    };

    const handleSelectProduct = (value: string, index: number) => {
        reset({
            ...watch(),
            targetGroups: fields.map((el, ind) => {
                return index !== ind
                    ? {
                          _id: el._id,
                          productGuid: el.productGuid,
                      }
                    : {
                          _id: el._id,
                          productGuid: value,
                      };
            }),
        });
    };

    const [publicationDateVisible, setPublicationDateVisible] = useState(false);
    const [archivingDateVisible, setArchivingDateVisible] = useState(false);

    const renderItem = (item: FormControlType) => {
        const name = item.name as keyof CreateNotificationFormInfo;

        switch (item.type) {
            case FormFieldsTypes.INPUT:
                return (
                    <Controller
                        control={control}
                        name={name}
                        rules={item.rules}
                        render={({ field, fieldState }) => (
                            <div className="flex flex-column gap-2">
                                <label htmlFor={item.name}>{item.label}</label>
                                <InputText
                                    {...field}
                                    className={classNames(styles.dropdownInput, fieldState.error && styles.redBorder)}
                                    value={field.value as string}
                                    placeholder={item.placeHolder}
                                />
                                {fieldState.error && (
                                    <span className={classNames(styles.errorHint)}>Пожалуйста, заполните поле</span>
                                )}
                            </div>
                        )}
                    />
                );
            case FormFieldsTypes.CHECKBOX:
                return (
                    <Controller
                        control={control}
                        name={name}
                        defaultValue={false}
                        rules={item.rules}
                        render={({ field, fieldState }) => (
                            <div className={classNames('flex', 'gap-2', 'align-items-center')}>
                                <label htmlFor={item.name}>{item.label}</label>
                                <Checkbox checked={field.value as boolean} onChange={field.onChange} />
                            </div>
                        )}
                    />
                );
            case FormFieldsTypes.IMAGE:
                return (
                    <div className="flex flex-column gap-2">
                        <label htmlFor={item.name}>{item.label}</label>
                        <CustomFileUpload onUpload={handleUploadFile} image={image} onClear={handleClearFile} />
                    </div>
                );
            case FormFieldsTypes.ARCHIVING_DATE:
                return (
                    <Controller
                        control={control}
                        name={name}
                        rules={item.rules}
                        render={({ field, fieldState }) => (
                            <div className="flex flex-column gap-2">
                                <label htmlFor={item.name}>{item.label}</label>
                                <Calendar
                                    onChange={(e) => {
                                        field.onChange(e.value);
                                        setValue(name, e.value ? convertDateToMoscowDateString(e.value) : undefined, {
                                            shouldValidate: true,
                                        });
                                        trigger(name);
                                    }}
                                    value={field.value ? new Date(field.value as string) : undefined}
                                    dateFormat="dd.mm.yy"
                                    locale="ru"
                                    placeholder={item.placeHolder}
                                    className={classNames(styles.calendar, fieldState.error && styles.redBorder)}
                                    visible={archivingDateVisible}
                                    showTime
                                    onVisibleChange={() => setArchivingDateVisible((prev) => !prev)}
                                />
                                {fieldState.error && (
                                    <span className={classNames(styles.errorHint)}>Пожалуйста, заполните поле</span>
                                )}
                            </div>
                        )}
                    />
                );
            case FormFieldsTypes.PUBLICATION_DATE:
                return (
                    <Controller
                        control={control}
                        name={name}
                        rules={item.rules}
                        render={({ field, fieldState }) => (
                            <div className="flex flex-column gap-2">
                                <label htmlFor={item.name}>{item.label}</label>
                                <Calendar
                                    onChange={(e) => {
                                        field.onChange(e.value);
                                        setValue(name, e.value ? convertDateToMoscowDateString(e.value) : undefined, {
                                            shouldValidate: true,
                                        });
                                        trigger(name);
                                    }}
                                    value={field.value ? new Date(field.value as string) : undefined}
                                    dateFormat="dd.mm.yy"
                                    locale="ru"
                                    placeholder={item.placeHolder}
                                    className={classNames(styles.calendar, fieldState.error && styles.redBorder)}
                                    visible={publicationDateVisible}
                                    showTime
                                    onVisibleChange={() => setPublicationDateVisible((prev) => !prev)}
                                />
                                {fieldState.error && (
                                    <span className={classNames(styles.errorHint)}>Пожалуйста, заполните поле</span>
                                )}
                            </div>
                        )}
                    />
                );
            case FormFieldsTypes.SINGLE_SELECT:
                return (
                    <Controller
                        control={control}
                        name={name}
                        rules={item.rules}
                        render={({ field, fieldState }) => (
                            <div className="flex flex-column gap-2">
                                <label htmlFor={name}>{item.label}</label>
                                <Dropdown
                                    {...field}
                                    options={categoryOptions}
                                    optionLabel="label"
                                    placeholder={item.placeHolder}
                                    className={classNames(
                                        styles.dropdownInput,
                                        styles.filter,
                                        fieldState.error && styles.redBorder,
                                    )}
                                />
                                {fieldState.error && (
                                    <span className={classNames(styles.errorHint)}>Пожалуйста, заполните поле</span>
                                )}
                            </div>
                        )}
                    />
                );
            case FormFieldsTypes.OTHER:
                return (
                    <div className={classNames('flex', 'flex-column')}>
                        <label htmlFor="targetGroups">Группы пользователей</label>
                        {targetGroups?.length
? (
                            <div className={classNames('flex', 'flex-column', styles.targetGroups)}>
                                {targetGroups?.map((group) => {
                                    const index = fields.findIndex((el) => el._id === group.id);

                                    return (
                                        <>
                                            <div
                                                className={classNames('flex', 'align-items-center', styles.group)}
                                                key={group.id}
                                            >
                                                <label htmlFor={group.name}>{group.name}</label>
                                                <Checkbox
                                                    checked={!!fields.find((el) => el._id === group.id)}
                                                    onChange={(e) => {
                                                        if (group.isProductRequired && !products?.length) {
                                                            fetchProducts();
                                                        }

                                                        if (e.checked) {
                                                            append({
                                                                _id: group.id,
                                                                name: group.name,
                                                            });
                                                        } else {
                                                            remove(index);
                                                        }
                                                    }}
                                                />
                                            </div>
                                            {group.isProductRequired && fields.find((el) => el._id === group.id) && (
                                                <>
                                                    <Controller
                                                        control={control}
                                                        name={`targetGroups.${index}.productGuid`}
                                                        rules={{ required: true }}
                                                        render={({ field, fieldState }) => (
                                                            <div className="flex flex-column gap-2">
                                                                <label htmlFor={`productGuid-${group.id}`}>
                                                                    Наименование продукта
                                                                </label>
                                                                <Dropdown
                                                                    {...field}
                                                                    options={
                                                                        products
                                                                            ?.filter((el) => !!el.name)
                                                                            .map((el) => {
                                                                                return {
                                                                                    label: el.name,
                                                                                    value: el.productGuid,
                                                                                };
                                                                            }) ?? []
                                                                    }
                                                                    value={field.value}
                                                                    onChange={(e) => handleSelectProduct(e.value, index)}
                                                                    optionLabel="label"
                                                                    placeholder="Выберите продукт"
                                                                    className={classNames(
                                                                        styles.dropdownInput,
                                                                        fieldState.error && styles.redBorder,
                                                                    )}
                                                                />
                                                                {fieldState.error && (
                                                                    <span className={classNames(styles.errorHint)}>
                                                                        Пожалуйста, заполните поле
                                                                    </span>
                                                                )}
                                                            </div>
                                                        )}
                                                    />
                                                    <Divider className={classNames(styles.divider)} />
                                                </>
                                            )}
                                        </>
                                    );
                                })}
                            </div>
                        )
: (
                            <div className={classNames(styles.errorTitle)}>
                                К сожалению, группы пользователей отсутствуют
                            </div>
                        )}
                    </div>
                );
            default:
                return null;
        }
    };

    return {
        renderItem,
        handleSubmit,
        onSubmit,
        isValid,
        isSubmitting,
    };
};
