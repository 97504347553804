import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Controller, useForm } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { useNavigate } from 'react-router-dom';

import { ReportType } from '../services/types';
import styles from './styles.module.scss';
import { createReport } from '../services';

const typeOptions = Object.keys(ReportType).map((key) => ({
    label: ReportType[key as keyof typeof ReportType],
    value: key as keyof typeof ReportType,
}));

export const ReportCreatePage = () => {
    const navigate = useNavigate();
    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
        trigger,
    } = useForm({
        defaultValues: {
            type: '',
            dateFrom: null,
            dateTo: null,
            month: null,
        },
    });

    const watchType = watch('type');
    const watchDateFrom = watch('dateFrom') as Date | null;

    const onSubmit = async (data: any) => {
        try {
            const { type, dateFrom, dateTo, month } = data;

            const reportData: any = { type };

            if (type === 'MONTHLY_SOLD') {
                const selectedMonthDate = month ? new Date(month) : new Date();
                const formattedMonth = `${String(selectedMonthDate.getMonth() + 1).padStart(2, '0')}-${selectedMonthDate.getFullYear()}`;
                reportData.month = formattedMonth;
            } else {
                if (dateFrom) {
                    const fromDate = new Date(dateFrom);
                    fromDate.setHours(16, 0, 0, 0);
                    reportData.dateFrom = fromDate;
                }

                if (dateTo) {
                    const toDate = new Date(dateTo);
                    toDate.setHours(16, 0, 0, 0);
                    reportData.dateTo = toDate;
                }
            }

            await createReport(reportData).then((res) => {
                navigate('/reports');
            });
        } catch (error) {
            console.log('error: ', error);
        }
    };

    return (
        <div className={classNames(styles.form, 'flex', 'flex-column')}>
            <div className={classNames('flex', 'flex-column', styles.formFields)}>
                <span className={classNames(styles.title)}>Создание отчета</span>

                <Controller
                    name="type"
                    control={control}
                    rules={{ required: 'Тип отчета обязателен' }}
                    render={({ field }) => (
                        <div className={classNames(styles.row, 'flex flex-column gap-2')}>
                            <label htmlFor="type">Тип отчета</label>
                            <Dropdown
                                {...field}
                                options={typeOptions}
                                placeholder="Выберите тип отчета"
                                className={errors.type ? 'p-invalid' : ''}
                            />
                            {errors.type && <small className="p-error">{errors.type.message}</small>}
                        </div>
                    )}
                />

                {watchType === 'MONTHLY_SOLD' ? (
                    <Controller
                        name="month"
                        control={control}
                        rules={{ required: 'Месяц обязателен' }}
                        render={({ field }) => (
                            <div className={classNames(styles.row, 'flex flex-column gap-2')}>
                                <label htmlFor="month">Месяц</label>
                                <Calendar
                                    {...field}
                                    view="month"
                                    dateFormat="mm.yy"
                                    placeholder="Выберите месяц"
                                    locale="ru"
                                    className={errors.month ? 'p-invalid' : ''}
                                />
                                {errors.month && <small className="p-error">{errors.month.message}</small>}
                            </div>
                        )}
                    />
                ) : (
                    <>
                        <Controller
                            name="dateFrom"
                            control={control}
                            rules={{ required: 'Дата начала обязательна' }}
                            render={({ field }) => (
                                <div className={classNames(styles.row, 'flex flex-column gap-2')}>
                                    <label htmlFor="dateFrom">Дата начала</label>
                                    <Calendar
                                        {...field}
                                        dateFormat="dd.mm.yy"
                                        placeholder="Выберите дату начала"
                                        locale="ru"
                                        className={errors.dateFrom ? 'p-invalid' : ''}
                                    />
                                    {errors.dateFrom && <small className="p-error">{errors.dateFrom.message}</small>}
                                </div>
                            )}
                        />

                        <Controller
                            name="dateTo"
                            control={control}
                            rules={{
                                required: 'Дата окончания обязательна',
                                validate: (value) => {
                                    if (!value) return true; // Если нет значения, валидируем

                                    if (!watchDateFrom) return true; // Если дата начала не выбрана, валидируем

                                    return (
                                        new Date(value) >= watchDateFrom ||
                                        'Дата окончания не может быть раньше даты начала'
                                    );
                                },
                            }}
                            render={({ field }) => (
                                <div className={classNames(styles.row, 'flex flex-column gap-2')}>
                                    <label htmlFor="dateTo">Дата окончания</label>
                                    <Calendar
                                        {...field}
                                        dateFormat="dd.mm.yy"
                                        placeholder="Выберите дату окончания"
                                        className={errors.dateTo ? 'p-invalid' : ''}
                                        locale="ru"
                                        onChange={(e) => {
                                            field.onChange(e.value);
                                            trigger('dateTo');
                                        }}
                                    />
                                    {errors.dateTo && <small className="p-error">{errors.dateTo.message}</small>}
                                </div>
                            )}
                        />
                    </>
                )}

                <div className={classNames(styles.row, 'flex', 'align-item-center', styles.buttonsContainer)}>
                    <Button severity="secondary" outlined onClick={() => window.location.reload()}>
                        Отмена
                    </Button>
                    <Button severity="secondary" outlined onClick={handleSubmit(onSubmit)}>
                        Создать
                    </Button>
                </div>
            </div>
        </div>
    );
};
