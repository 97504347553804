import { configureStore } from '@reduxjs/toolkit';
import {
    cardReducer,
    loadingReducer,
    userReducer,
} from './slices';

const store = configureStore({
    reducer: {
        user: userReducer,
        loading: loadingReducer,
        card: cardReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export { store };
export * from './thunk';
