import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { ConfirmModal } from '@libs/components';

import styles from './styles.module.scss';
import { useRefundPageFacade } from './useRefundPageFacade';
import { refundFields } from '../services/types';

const actions = [
    {
        label: 'Чек отправлен вручную',
        code: 'RECEIPT_SENT_MANUALLY',
    },
    {
        label: 'Приостановить печать чека',
        code: 'PAUSE_REFUND_RECEIPT_SENDING',
    },
    {
        label: 'Возобновить печать чека',
        code: 'RESUME_REFUND_RECEIPT_SENDING',
    },
];

export const RefundPage = () => {
    const { refund, setSelectedAction, isOpen, openModal, closeModal, eventHandler } = useRefundPageFacade();

    return (
        <div className={classNames(styles.form, 'flex', 'flex-column')}>
            <div className={classNames('flex', 'flex-column', styles.formFields)}>
                <div className={classNames(styles.filters, 'flex', 'align-items-center')}>
                    <span className={classNames(styles.title)}>Возврат</span>
                    <div className={classNames('flex', 'align-items-center', styles.btnGroup)}>
                        <Dropdown
                            onChange={(e) => {
                                setSelectedAction(actions.find((item) => e.value === item.code));
                                openModal();
                            }}
                            options={actions}
                            optionLabel="label"
                            placeholder="Выбрать действие"
                            optionValue="code"
                            className={classNames(styles.filter)}
                        />
                    </div>
                </div>
                {refund &&
                    Object.entries(refund).map(([key, value]) => {
                        const keyName = key as keyof typeof refundFields;

                        if (refundFields[keyName]) {
                            return (
                                <div className={styles.field} key={keyName}>
                                    <label htmlFor={refundFields[keyName]}>{refundFields[keyName] || ''}</label>
                                    <div>
                                        <InputText
                                            style={{ width: 400 }}
                                            id={refundFields[keyName]}
                                            disabled
                                            value={value as string}
                                        />
                                    </div>
                                </div>
                            );
                        } else {
                            return null;
                        }
                    })}
            </div>

            <ConfirmModal
                header={'Подтвердить действие'}
                confirmButtonText={'Подтвердить'}
                onConfirm={eventHandler}
                onClose={closeModal}
                isVisible={isOpen}
                children={<p>Вы уверены?</p>}
            />
        </div>
    );
};
