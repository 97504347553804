import { useEffect, useState } from 'react';
import { Controller, type UseFormHandleSubmit, useForm, useFieldArray } from 'react-hook-form';
import { store } from '@store/store';
import { type FileUploadSelectEvent } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import { Checkbox } from 'primereact/checkbox';
import CustomFileUpload from '@libs/components/data/CustomFileUpload';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { downloadFileThunk } from '@store/store/thunk/attachment.thunk';
import { hideLoader, showLoader } from '@store/store/slices/loading.slice';
import { usePermissions } from '@libs/hooks';
import { convertDateToMoscowDateString } from '@libs/utils';
import { Divider } from 'primereact/divider';
import { getProducts } from '@modules/AdminPage/services';
import type { Product } from '@libs/types';

import {
    type FormControlType,
    FormFieldsTypes,
    type EditNotificationFormInfo,
    NotificationStatus,
    type TargetGroupType,
    type TargetGroupEditType,
} from '../services/types';
import { getNotificationById, getTargetGroups, updateNotification } from '../services';
import styles from './styles.module.scss';

type UseNotificationsEditFacadeResult = {
    renderItem: (item: FormControlType) => JSX.Element | null;
    handleSubmit: UseFormHandleSubmit<EditNotificationFormInfo, undefined>;
    isValid: boolean;
    isDirty: boolean;
    onSubmit: (data: EditNotificationFormInfo) => void;
};

export const useNotificationsEditFacade = (): UseNotificationsEditFacadeResult => {
    const {
        control,
        reset,
        handleSubmit,
        setValue,
        trigger,
        watch,
        formState: { isValid, isDirty },
    } = useForm<EditNotificationFormInfo>({
        mode: 'onChange',
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'targetGroups',
    });

    const { isAdminOrMarket } = usePermissions();

    const { notificationId } = useParams();
    const [image, setImage] = useState<string>();
    const [targetGroups, setTargetGroups] = useState<TargetGroupType[]>();
    const [prevTargetGroups, setPrevTargetGroups] = useState<TargetGroupEditType[]>([]);
    const [products, setProducts] = useState<Product[]>();
    const notificationStatus = watch('status');

    const onSubmit = async (data: EditNotificationFormInfo) => {
        const formData = new FormData();

        Object.entries(data).forEach(([key, value]) => {
            if (value !== undefined && value !== null && key !== 'authorId' && key !== 'publicationDate') {
                if (Array.isArray(value)) {
                    let groups: any[] = [];

                    value
                        .filter((item) => Number.isFinite(item._id))
                        .forEach((item) => {
                            groups = [
                                ...groups,
                                ...[
                                    item.productGuid
                                        ? {
                                              id: item._id,
                                              productGuid: item.productGuid,
                                          }
                                        : {
                                              id: item._id,
                                          },
                                ],
                            ];
                        });

                    if (groups.length) {
                        formData.append('targetGroups', JSON.stringify(groups));
                    }
                } else {
                    formData.append(key, value as string);
                }
            }

            if (
                value !== undefined &&
                value !== null &&
                data.status === NotificationStatus.PENDING &&
                key === 'publicationDate'
            ) {
                formData.append(key, value as string);
            }
        });

        try {
            if (notificationId) {
                await updateNotification(notificationId, formData).then(() => {
                    window.location.reload();
                });
            }
        } catch (error) {
            console.log('error: ', error);
        }
    };

    const fetchNotification = async () => {
        try {
            if (notificationId) {
                store.dispatch(showLoader());
                await getNotificationById(notificationId).then((res) => {
                    setPrevTargetGroups(res.data.targetGroups);

                    if (!(res.data.image instanceof File)) {
                        store
                            .dispatch(
                                downloadFileThunk({
                                    bucket: res.data.image?.bucket,
                                    fileId: res.data.image?.fileId,
                                }),
                            )
                            .then((resp) => {
                                reset({
                                    ...res.data,
                                    image: resp.payload as File,
                                });
                                const result = unwrapResult(resp);
                                setImage(result);
                            });
                    }
                });
                store.dispatch(hideLoader());
            }
        } catch (error) {
            console.log('error: ', error);
        }
    };

    const fetchTargetGroups = async () => {
        try {
            store.dispatch(showLoader());
            const data = await getTargetGroups();
            setTargetGroups(data.data.data);
            store.dispatch(hideLoader());
        } catch (error) {
            console.log('error: ', error);
        }
    };

    const fetchProducts = async () => {
        try {
            const data = await getProducts({});
            setProducts(data.data.data.rows);
        } catch (error) {
            console.log('error: ', error);
        }
    };

    useEffect(() => {
        fetchNotification();
        fetchTargetGroups();
        fetchProducts();
    }, []);

    useEffect(() => {
        if (targetGroups?.length && prevTargetGroups?.length) {
            prevTargetGroups.forEach((item) => {
                const group = targetGroups.find((group) => group.id === item.id);

                if (group) {
                    append({
                        _id: group.id,
                        name: group.name,
                        productGuid: item.productGuid,
                    });
                }
            });
        }
    }, [targetGroups, prevTargetGroups]);

    const handleUploadFile = (e: FileUploadSelectEvent) => {
        setValue('image', e.files[0], { shouldDirty: true });
        const fileUrl = URL.createObjectURL(e.files[0]);
        setImage(fileUrl);
    };

    const handleClearFile = () => {
        setValue('image', undefined, { shouldDirty: true });
        setImage('');
    };

    const handleSelectProduct = (value: string, index: number) => {
        reset({
            ...watch(),
            targetGroups: fields.map((el, ind) => {
                return index !== ind
                    ? {
                          _id: el._id,
                          productGuid: el.productGuid,
                      }
                    : {
                          _id: el._id,
                          productGuid: value,
                      };
            }),
        });
    };

    const [publicationDateVisible, setPublicationDateVisible] = useState(false);
    const [archivingDateVisible, setArchivingDateVisible] = useState(false);

    const renderItem = (item: FormControlType) => {
        const name = item.name as keyof EditNotificationFormInfo;

        switch (item.type) {
            case FormFieldsTypes.INPUT:
                return (
                    <Controller
                        control={control}
                        name={name}
                        rules={item.rules}
                        render={({ field, fieldState }) => (
                            <div className="flex flex-column gap-2">
                                <label htmlFor={item.name}>{item.label}</label>
                                <InputText
                                    {...field}
                                    className={classNames(styles.dropdownInput, fieldState.error && styles.redBorder)}
                                    value={field.value as string}
                                    placeholder={item.placeHolder}
                                    disabled={!isAdminOrMarket ? true : item.disabled}
                                />
                                {fieldState.error && (
                                    <span className={classNames(styles.errorHint)}>Пожалуйста, заполните поле</span>
                                )}
                            </div>
                        )}
                    />
                );
            case FormFieldsTypes.CHECKBOX:
                return (
                    <Controller
                        control={control}
                        name={name}
                        defaultValue={false}
                        rules={item.rules}
                        render={({ field, fieldState }) => (
                            <div className={classNames('flex', 'gap-2', 'align-items-center')}>
                                <label htmlFor={item.name}>{item.label}</label>
                                <Checkbox
                                    checked={field.value as boolean}
                                    onChange={field.onChange}
                                    disabled={!isAdminOrMarket}
                                />
                            </div>
                        )}
                    />
                );
            case FormFieldsTypes.IMAGE:
                return (
                    <div className="flex flex-column gap-2">
                        <label htmlFor={item.name}>{item.label}</label>
                        <CustomFileUpload
                            disabled={!isAdminOrMarket}
                            onUpload={handleUploadFile}
                            image={image}
                            onClear={handleClearFile}
                        />
                    </div>
                );
            case FormFieldsTypes.ARCHIVING_DATE:
                return (
                    <Controller
                        control={control}
                        name={name}
                        rules={item.rules}
                        render={({ field, fieldState }) => (
                            <div className="flex flex-column gap-2">
                                <label htmlFor={item.name}>{item.label}</label>
                                <Calendar
                                    onChange={(e) => {
                                        field.onChange(e.value);
                                        setValue(name, e.value ? convertDateToMoscowDateString(e.value) : undefined, {
                                            shouldValidate: true,
                                        });
                                        trigger(name);
                                    }}
                                    value={field.value ? new Date(field.value as string) : undefined}
                                    dateFormat="dd.mm.yy"
                                    locale="ru"
                                    disabled={!isAdminOrMarket}
                                    placeholder={item.placeHolder}
                                    className={classNames(styles.calendar, fieldState.error && styles.redBorder)}
                                    visible={archivingDateVisible}
                                    showTime
                                    onVisibleChange={() => setArchivingDateVisible((prev) => !prev)}
                                />
                                {fieldState.error && (
                                    <span className={classNames(styles.errorHint)}>Пожалуйста, заполните поле</span>
                                )}
                            </div>
                        )}
                    />
                );
            case FormFieldsTypes.PUBLICATION_DATE:
                return (
                    <Controller
                        control={control}
                        name={name}
                        rules={item.rules}
                        render={({ field, fieldState }) => (
                            <div className="flex flex-column gap-2">
                                <label htmlFor={item.name}>{item.label}</label>
                                <Calendar
                                    onChange={(e) => {
                                        field.onChange(e.value);
                                        setValue(name, e.value ? convertDateToMoscowDateString(e.value) : undefined, {
                                            shouldValidate: true,
                                        });
                                        trigger(name);
                                    }}
                                    value={field.value ? new Date(field.value as string) : undefined}
                                    dateFormat="dd.mm.yy"
                                    locale="ru"
                                    disabled={!isAdminOrMarket}
                                    placeholder={item.placeHolder}
                                    className={classNames(styles.calendar, fieldState.error && styles.redBorder)}
                                    visible={publicationDateVisible}
                                    showTime
                                    onVisibleChange={() => setPublicationDateVisible((prev) => !prev)}
                                />
                                {fieldState.error && (
                                    <span className={classNames(styles.errorHint)}>Пожалуйста, заполните поле</span>
                                )}
                            </div>
                        )}
                    />
                );
            case FormFieldsTypes.SINGLE_SELECT:
                return (
                    <Controller
                        control={control}
                        name={name}
                        rules={item.rules}
                        render={({ field, fieldState }) => (
                            <div className="flex flex-column gap-2">
                                <label htmlFor={name}>{item.label}</label>
                                <Dropdown
                                    {...field}
                                    options={item.options}
                                    optionLabel="label"
                                    placeholder={item.placeHolder}
                                    className={classNames(
                                        styles.dropdownInput,
                                        styles.filter,
                                        fieldState.error && styles.redBorder,
                                    )}
                                    disabled={!isAdminOrMarket}
                                />
                                {fieldState.error && (
                                    <span className={classNames(styles.errorHint)}>Пожалуйста, заполните поле</span>
                                )}
                            </div>
                        )}
                    />
                );
            case FormFieldsTypes.OTHER:
                return (
                    <div className={classNames('flex', 'flex-column')}>
                        <label htmlFor="targetGroups">Группы пользователей</label>
                        {targetGroups?.length
? (
                            <div className={classNames('flex', 'flex-column', styles.targetGroups)}>
                                {targetGroups?.map((group) => {
                                    const index = fields.findIndex((el) => el._id === group.id);

                                    return (
                                        <>
                                            <div
                                                className={classNames('flex', 'align-items-center', styles.group)}
                                                key={group.id}
                                            >
                                                <label htmlFor={group.name}>{group.name}</label>
                                                <Checkbox
                                                    checked={!!fields.find((el) => el._id === group.id)}
                                                    onChange={(e) => {
                                                        if (e.checked) {
                                                            append({
                                                                _id: group.id,
                                                                name: group.name,
                                                            });
                                                        } else {
                                                            remove(index);
                                                        }
                                                    }}
                                                    disabled={notificationStatus !== NotificationStatus.PENDING}
                                                />
                                            </div>
                                            {group.isProductRequired && fields.find((el) => el._id === group.id) && (
                                                <>
                                                    <Controller
                                                        control={control}
                                                        name={`targetGroups.${index}.productGuid`}
                                                        rules={{ required: true }}
                                                        render={({ field, fieldState }) => (
                                                            <div className="flex flex-column gap-2">
                                                                <label htmlFor={`productGuid-${group.id}`}>
                                                                    Наименование продукта
                                                                </label>
                                                                <Dropdown
                                                                    {...field}
                                                                    options={
                                                                        products
                                                                            ?.filter((el) => !!el.name)
                                                                            .map((el) => {
                                                                                return {
                                                                                    label: el.name,
                                                                                    value: el.productGuid,
                                                                                };
                                                                            }) ?? []
                                                                    }
                                                                    value={
                                                                        fields.find((el) => el._id === group.id)
                                                                            ?.productGuid ?? field.value
                                                                    }
                                                                    onChange={(e) => handleSelectProduct(e.value, index)}
                                                                    optionLabel="label"
                                                                    placeholder="Выберите продукт"
                                                                    className={classNames(
                                                                        styles.dropdownInput,
                                                                        fieldState.error && styles.redBorder,
                                                                    )}
                                                                    disabled={
                                                                        notificationStatus !==
                                                                        NotificationStatus.PENDING
                                                                    }
                                                                />
                                                                {fieldState.error && (
                                                                    <span className={classNames(styles.errorHint)}>
                                                                        Пожалуйста, заполните поле
                                                                    </span>
                                                                )}
                                                            </div>
                                                        )}
                                                    />
                                                    <Divider className={classNames(styles.divider)} />
                                                </>
                                            )}
                                        </>
                                    );
                                })}
                            </div>
                        )
: (
                            <div className={classNames(styles.errorTitle)}>
                                К сожалению, группы пользователей отсутствуют
                            </div>
                        )}
                    </div>
                );
            default:
                return null;
        }
    };

    return {
        renderItem,
        handleSubmit,
        isValid,
        onSubmit,
        isDirty,
    };
};
