import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useModal } from '@libs/utils';
import { store } from '@store/store';
import { hideLoader, showLoader } from '@store/store/slices/loading.slice';

import { getRefundById, postRefundEvent } from '../services';

type UseOrderPageFacadeResult = {
    refund: object;
    setSelectedAction: React.Dispatch<React.SetStateAction<{ label: string; code: string } | undefined>>;
    isOpen: boolean;
    openModal: () => void;
    closeModal: () => void;
    eventHandler: () => Promise<void>;
};

export const useRefundPageFacade = (): UseOrderPageFacadeResult => {
    const { refundId } = useParams();

    const [selectedAction, setSelectedAction] = useState<{ label: string; code: string } | undefined>();
    const { isOpen, openModal, closeModal } = useModal();
    const [refund, setRefund] = useState({});

    const fetchRefund = async () => {
        try {
            if (refundId) {
                store.dispatch(showLoader());
                const data = await getRefundById(refundId);
                setRefund(data.data.data);
                store.dispatch(hideLoader());
            }
        } catch (error) {
            console.log('error: ', error);
        }
    };

    const eventHandler = async () => {
        try {
            if (refundId && selectedAction?.code) await postRefundEvent(refundId, selectedAction.code);
        } catch (error) {
            console.error('error: ', error);
        }

        closeModal();
    };

    useEffect(() => {
        fetchRefund();
    }, []);

    return {
        refund,
        setSelectedAction,
        isOpen,
        openModal,
        closeModal,
        eventHandler,
    };
};
