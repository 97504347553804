import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { useParams } from 'react-router-dom';
import { getArticleContent, updateArticleContent } from '@modules/AdminPage/services';
import { type JSONContent } from '@tiptap/react';
import { usePermissions } from '@libs/hooks';
import { HasAccess } from '@libs/components/access';

import styles from '../styles/styles.module.scss';
import Tiptap from './Tiptap/Tiptap';

export const Editor: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { rootAdminAndMarket } = usePermissions();
    const [content, setContent] = useState<JSONContent>({
        type: 'doc',
        content: [
            {
                type: 'paragraph',
                content: [
                    {
                        type: 'text',
                        text: 'Hello World!',
                    },
                ],
            },
        ],
    });
    const [shouldSave, setShouldSave] = useState<boolean>(false);

    useEffect(() => {
        if (id) {
            (async () => {
                await getArticleContent(id)
                    .then((response) => {
                        const parseContent: { content: JSONContent[] } = JSON.parse(response.data.content);

                        setContent({
                            type: 'doc',
                            content: parseContent.content,
                        });
                    })
                    .catch((error) => console.error('Error fetching content:', error));
            })();
        }
    }, [id]);

    const handleSave = () => {
        setShouldSave(true);
    };

    const handleCancel = () => {
        if (id) {
            (async () => {
                await getArticleContent(id)
                    .then((response) => {
                        const parseContent: { content: JSONContent[] } = JSON.parse(response.data.content);

                        setContent({
                            type: 'doc',
                            content: parseContent.content,
                        });
                    })
                    .catch((error) => console.error('Error fetching content:', error));
            })();
        }
    };

    const saveContent = (newContent: JSONContent) => {
        if (id) {
            (async () => {
                await updateArticleContent(id, { content: newContent.content })
                    .then((response) => {
                        setContent(content);
                    })
                    .catch((error) => console.error('Error saving content:', error))
                    .finally(() => setShouldSave(false));
            })();
        }
    };

    return (
        <div className={classNames('flex', 'flex-1')}>
            <div className={classNames(styles.wrapper)}>
                <div className={classNames(styles.filters, 'flex', 'align-items-center')}>
                    <span className={classNames(styles.title)}>Редактор контента</span>
                    <div className={classNames('flex', 'align-items-center', styles.btnGroup)}>
                        <HasAccess permissions={rootAdminAndMarket}>
                            <Button
label="Отменить" severity="secondary" outlined
onClick={handleCancel} />
                            <Button
label="Сохранить" severity="secondary" outlined
onClick={handleSave} />
                        </HasAccess>
                    </div>
                </div>
                <Tiptap content={content} shouldSave={shouldSave} onSave={saveContent} />
            </div>
        </div>
    );
};
