import { useNavigate, useParams } from 'react-router-dom';
import { getUser, updateUserStatus } from '@modules/AdminPage/Users/services';
import { useEffect, useState } from 'react';
import { UserInfo, UserStatus } from '@modules/AdminPage/Users/services/type';
import { SubscriptionInfo } from '@modules/AdminPage/Subscriptions/services/types';
import { getSubscriptions } from '@modules/AdminPage/Subscriptions/services';
import type { DataTableRowClickEvent } from 'primereact/datatable';
import { useModal } from '@libs/utils';

export const useUserDetail = () => {
    const { userId } = useParams();
    const navigate = useNavigate();

    const [user, setUser] = useState<UserInfo | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState(0);
    const [subs, setSubs] = useState<SubscriptionInfo[]>([]);
    const [userLoader, setUserLoader] = useState<boolean>(false);
    const [selectedStatus, setSelectedStatus] = useState<{ label: string; code: string } | undefined>();

    const { isOpen, openModal, closeModal } = useModal();

    const getUserData = async () => {
        if (!userId) {
            return;
        }
        try {
            setUserLoader(true);
            const response = await getUser(userId);
            setUser(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            setUserLoader(false);
        }
    };

    const getSubsData = async () => {
        if (!userId) {
            return;
        }

        try {
            const response = await getSubscriptions({
                userId,
                pageNumber: currentPage,
                pageSize: 25,
            });

            setSubs(response.data.data.rows);
            setTotalPages(response.data.data.totalPages);
        } catch (error) {
            console.error(error);
        }
    };

    const handleTableRowClick = (e: DataTableRowClickEvent) => {
        navigate(`/subscriptions/${e.data.id}`);
    };

    const updateUserStatusHandler = async () => {
        await updateUserStatus({
            customerId: [user?.customerId || ''],
            status: user?.status === UserStatus.ACTIVE ? UserStatus.FROZEN : UserStatus.ACTIVE,
        });
        closeModal();
        await getUserData();
    };

    useEffect(() => {
        getUserData();
    }, [userId]);

    useEffect(() => {
        getSubsData();
    }, [currentPage]);

    return {
        user,
        loader: userLoader,
        subs,
        totalPages,
        currentPage,
        handleTableRowClick,
        setCurrentPage,
        updateUserStatusHandler,
        isOpen,
        openModal,
        closeModal,
        setSelectedStatus,
        selectedStatus,
    };
};
