import { useEffect, useState } from 'react';
import { UserInfo, UsersListFilter, UserStatus } from '@modules/AdminPage/Users/services/type';
import { exportUsers, getUsers, updateUserStatus } from '@modules/AdminPage/Users/services';
import type { DataTableRowClickEvent, SortOrder } from 'primereact/datatable';
import { useNavigate } from 'react-router-dom';
import { exportFileWithCSV, useModal } from '@libs/utils';
import { usePermissions } from '@libs/hooks';

export const useUsersList = () => { 
    const navigate = useNavigate();

    const [users, setUsers] = useState<UserInfo[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState(0);
    const [usersFilter, setUsersFilter] = useState<UsersListFilter | null>(null);
    const [selectedUsers, setSelectedUsers] = useState<UserInfo[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<{ label: string; code: string } | undefined>();
    const [sortField, setSortField] = useState<string>('customerId');
    const [sortOrder, setSortOrder] = useState<SortOrder>(1);
    const [userInput, setUserInput] = useState<string>('');
    const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);

    const { isOpen, openModal, closeModal } = useModal();

    const { isMarketAdmin, isViewAdmin } = usePermissions();

    const getData = async () => {
        try {
            const filters = {
                status: usersFilter?.status,
                fullName: usersFilter?.userName,
            }
            const response = await getUsers({ 
              ...filters, 
              pageable: {
                pageNumber:
                currentPage, 
                pageSize: 25,
                sortField: sortField,
                sortOrder: sortOrder === 1 ? 'desc' : 'asc',
              } 
            });
            setUsers(response.data.rows);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error(error);
        }
    };

    const handleSort = (e: any) => {
        setSortField(e.sortField);
        setSortOrder(e.sortOrder);
    };

    useEffect(() => {
        getData();
    }, [currentPage, usersFilter, sortField, sortOrder]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setUserInput(value);

        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        const newTimeout = setTimeout(() => {
            setUsersFilter((prevState) => ({
                ...prevState,
                userName: value,
            }));
        }, 500);

        setDebounceTimeout(newTimeout);
    };

    const handleTableRowClick = (e: DataTableRowClickEvent) => {
        navigate(`/users/${e.data.customerId}`);
    };

    const fetchExport = async () => {
        try {
            const data = await exportUsers({ 
              status: usersFilter?.status,
              fullName: usersFilter?.userName, 
              pageable: {
                pageNumber:
                currentPage, 
                pageSize: 25,
                sortField: sortField,
                sortOrder: sortOrder === 1 ? 'desc' : 'asc',
              } 
            });
            const blob = new Blob([data.data], { type: 'text/csv;charset=utf-8;' });
            exportFileWithCSV(blob, 'users');
        } catch (error) {
            console.log('error: ', error);
        }
    };

    const handleSelectUserFilter = (e: any) => {
        setUsersFilter((prevState) => ({
            ...prevState,
            userGuid: e.code,
            userName: e.name,
        }))
    };

    const updateUserStatusHandler = async () => {
        await updateUserStatus({
            customerId: selectedUsers.map(({ customerId }) => customerId),
            status: selectedStatus?.code as UserStatus,
        });
        setSelectedStatus(undefined);
        setSelectedUsers([]);
        await getData();
        closeModal();
    };

    return {
        users,
        setCurrentPage,
        totalPages,
        usersFilter,
        setUsersFilter,
        handleTableRowClick,
        currentPage,
        fetchExport,
        selectedUsers,
        setSelectedUsers,
        selectedStatus,
        setSelectedStatus,
        isOpen,
        openModal,
        closeModal,
        updateUserStatusHandler,
        handleSelectUserFilter,
        isRowsDisabled: isMarketAdmin || isViewAdmin,
        sortField,
        sortOrder,
        handleSort,
        handleInputChange,
        userInput,
    };
};
