import { useEffect, useState } from 'react';
import { type AppDispatch, RootState } from '@store/store';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { type Control, type UseFormHandleSubmit, useForm } from 'react-hook-form';
import { type DatashopTariff, type Product } from '@libs/types';
import {
    PostProductRequestType,
    productPostfields,
    type TariffsFiltersParams,
} from '@modules/AdminPage/services/types';
import { getProductById, getTariffs, updateProduct } from '@modules/AdminPage/services';
import { downloadFileThunk } from '@store/store/thunk/attachment.thunk';
import { unwrapResult } from '@reduxjs/toolkit';
import { type DataTableRowClickEvent, DataTableStateEvent, type SortOrder } from 'primereact/datatable';
import { type FileUploadSelectEvent } from 'primereact/fileupload';
import { hideLoader, showLoader } from '@store/store/slices/loading.slice';

type UseOrderPageFacadeResult = {
    control: Control<Product, any>;
    tariffs?: DatashopTariff[];
    isValid: boolean;
    isDirty: boolean;
    handleTableRowClick: (e: DataTableRowClickEvent) => void;
    handleSubmit: UseFormHandleSubmit<Product, any>;
    tariffsFilters?: TariffsFiltersParams;
    setTariffsFilters: React.Dispatch<React.SetStateAction<TariffsFiltersParams | undefined>>;
    image?: string;
    cartImage?: string;
    handleUploadFile: (e: FileUploadSelectEvent, field: string) => void;
    handleClearFile: (field: string) => void;
    onSubmit: (data: PostProductRequestType) => void;
    currentPage: number;
    totalPages: number;
    setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
    sortField: string;
    sortOrder: SortOrder;
    handleSort: (e: DataTableStateEvent) => void;
};

export const useProductPageFacade = (): UseOrderPageFacadeResult => {
    const { productId } = useParams();

    const navigate = useNavigate();

    const dispatch = useDispatch<AppDispatch>();

    const {
        control,
        watch,
        reset,
        handleSubmit,
        setValue,
        formState: { isDirty, isValid },
    } = useForm<Product>({
        mode: 'onBlur',
    });

    const [tariffs, setTariffs] = useState<DatashopTariff[]>();
    const [tariffsFilters, setTariffsFilters] = useState<TariffsFiltersParams>();
    const [cartImage, setCartImage] = useState<string>();
    const [image, setImage] = useState<string>();
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState(0);
    const [sortField, setSortField] = useState<string>('id');
    const [sortOrder, setSortOrder] = useState<SortOrder>(1);

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                dispatch(showLoader());
                if (productId) {
                    await getProductById(productId).then((res) => {
                        reset(res.data);

                        if (!(res.data.image instanceof File)) {
                            dispatch(
                                downloadFileThunk({
                                    bucket: res.data.image?.bucket,
                                    fileId: res.data.image?.fileId,
                                }),
                            ).then((resp) => {
                                const result = unwrapResult(resp);
                                setImage(result);
                                setValue('image', resp.payload as File);
                            });
                        }

                        if (!(res.data.cartImage instanceof File)) {
                            dispatch(
                                downloadFileThunk({
                                    bucket: res.data.cartImage?.bucket,
                                    fileId: res.data.cartImage?.fileId,
                                }),
                            ).then((response) => {
                                const result = unwrapResult(response);
                                setCartImage(result);
                                setValue('cartImage', response.payload as File);
                            });
                        }
                    });
                }
            } catch (error) {
                console.log('error: ', error);
            } finally {
                dispatch(hideLoader());
            }
        };

        fetchProduct();
    }, []);

    useEffect(() => {
        const fetchTariffs = async () => {
            try {
                if (watch('productGuid')) {
                    const data = await getTariffs({
                        ...tariffsFilters,
                        productGuid: watch('productGuid'),
                        pageable: {
                            pageNumber: currentPage,
                            pageSize: 25,
                            sortField,
                            sortOrder: sortOrder === 1 ? 'desc' : 'asc',
                        },
                    });

                    setTotalPages(data.data.data.totalPages);
                    setTariffs(data.data.data.rows);
                }
            } catch (error) {
                console.log('error: ', error);
            }
        };

        fetchTariffs();
    }, [tariffsFilters, watch('productGuid'), sortField, sortOrder, currentPage]);

    const handleTableRowClick = (e: DataTableRowClickEvent) => {
        navigate(`/tariffs/${e.data.id}`);
    };

    const handleUploadFile = (e: FileUploadSelectEvent, field: string) => {
        setValue(field as keyof Product, e.files[0], { shouldDirty: true });
        const fileUrl = URL.createObjectURL(e.files[0]);
        if (field === 'image') {
            setImage(fileUrl);
        } else {
            setCartImage(fileUrl);
        }
    };

    const handleClearFile = (field: string) => {
        setValue(field as keyof Product, undefined, { shouldDirty: true });

        if (field === 'image') {
            setImage('');
        } else {
            setCartImage('');
        }
    };

    const handleSort = (e: any) => {
        setSortField(e.sortField);
        setSortOrder(e.sortOrder);
    };

    const onSubmit = async (data: PostProductRequestType) => {
        const formData = new FormData();

        Object.entries(data).forEach(([key, value]) => {
            if (value !== undefined && value !== null && productPostfields.includes(key)) {
                if (key === 'isIdentityRequired') {
                    formData.append('identityRequired', value as string);
                } else {
                    formData.append(key, value as string);
                }
            }
        });

        try {
            if (productId) {
                await updateProduct(productId, formData).then(() => {
                    window.location.reload();
                });
            }
        } catch (error) {
            console.log('error: ', error);
        }
    };

    return {
        control,
        tariffs,
        isDirty,
        isValid,
        handleTableRowClick,
        handleSubmit,
        tariffsFilters,
        setTariffsFilters,
        image,
        cartImage,
        handleClearFile,
        handleUploadFile,
        onSubmit,
        currentPage,
        totalPages,
        setCurrentPage,
        sortField,
        sortOrder,
        handleSort,
    };
};
